<div class="left-sidenav" [ngClass]="{ 'custom-background': isCustomBackground }">
  <!-- LOGO -->
  <div class="brand">
    <a href="index.html" class="logo">
      <span>
        <img src="./assets/Menu sections/newbuupass_icons.svg" />
      </span>
    </a>
  </div>
  <div class="company-selector p-3">

    <!-- Example single danger button -->
    <div class="btn-group">
      <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <img class="somatech-icon" src="./assets/Menu sections/Nick.png" alt=""> <span
          class="somatech ms-2">{{companyName}}</span>
        <!--<img class="ms-1" src="./assets/Menu sections/bus/downn.png" alt="">-->
      </button>
    </div>
  </div>
  <!--end logo-->
  <div class="menu-content h-100" data-simplebar="init">
    <div class="top-menu">
      <ul *ngFor="let val of menuList" class="main-list">
        <li class="parent-list">
          <ul [ngClass]="val.menu_opened ? 'colored-menu' : 'non-colored' ">
            <img *ngIf="val.menu_opened; else closedImage" routerLink="{{ val.url }}" class="sidebar-icon mx-2"
              src="./assets/Menu sections/{{ val.openedImage }}" width="12%" />
            <ng-template #closedImage>
              <img routerLink="{{ val.url }}" class="sidebar-icon mx-2" src="./assets/Menu sections/{{ val.icon }}"
                width="12%" />
            </ng-template>
            <span routerLink="{{ val.url }}" (click)="toggleMenu(val)"
             class="sidebar-menu ms-1"
              [ngClass]="val.menu_opened ? 'open-menu' : 'close-menu' ">
              {{ val.title }}
            </span>
            <img *ngIf="val.submenu  else blankDiv" class="submenu-icon travel_management_icons" src="" width="12%"
              (click)="toggleSubmenu1(val)" />
            <ng-template #blankDiv>
              <div class="blank-div">
              </div>
            </ng-template>
          </ul>

          <!-- Submenu -->
          <ul *ngIf="val.showSubmenu">
            <li  *ngFor="let subItem of val.submenu" class="parent-list">
              <img *ngIf="subItem.menu_opened; else closedImage" routerLink="{{ subItem.url }}"
                class="sidebar-icon ms-4" src="./assets/Menu sections/{{ subItem.openedImage }}" width="12%" />
              <ng-template #closedImage>

                <img routerLink="{{ subItem.url }}" class="sidebar-icon ms-4 "
                  src="./assets/Menu sections/{{ subItem.icon }}" width="12%" />
              </ng-template>
              <a routerLink="{{ subItem.url }}" (click)="toggleSubMenu(subItem)" class=""
              [ngClass]="subItem.menu_opened ? 'subItem-colored' : 'subItem-black'  ">
                {{ subItem.title }}
              </a>
            </li>
          </ul>
          <!-- End Submenu -->
        </li>
      </ul>
    </div>
    <div class="bottom-menu p-2">
      <div class="dropdown">
        <button class="btn dropdown-toggle header_dropdown sidebar-menu" type="button" id="dropdownMenuButton1"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img width="16" src="./assets/Menu sections/chat.png" alt="">
            Customer Support <img class="mx-3" width="16" src="./assets/Menu sections/dropdown-side.png" alt="">
        </button>
        <ul class="dropdown-menu customer-support-modal" aria-labelledby="dropdownMenuButton1">
          <li class="parent-list">
            <img class="sidebar-icon mx-1" src="./assets/Menu sections/phoneIcon.png" width="12%" />
            <span class="sidebar-menu ms-2">
              Call +254767902
            </span>
          </li>
          <li class="parent-list">
            <img class="sidebar-icon mx-1" src="./assets/Menu sections/email.png" width="12%" />
            <span class="sidebar-menu ms-2">
              Email joe&#64;gmail.com
            </span>
          </li>
        </ul>
    </div>
    <ul *ngFor="let val of bottomMenuList" class="main-list mx-2">
      <li class="parent-list" routerLink="{{ val.url }}" (click)="auth.logout()">
        <img class="sidebar-icon mx-1" src="./assets/Menu sections/{{ val.icon }}" width="12%" />
        <span class="sidebar-menu ms-2">
          Logout
        </span>
      </li>
    </ul>


      <!--<div class="row">
        <div class="col-md-6 dark_btn">
          <li class="parent-list">
            <img class="sidebar-icon ms-2" src="./assets/Menu sections/half  moon.png" width="12%">

            <span class="sidebar-menu" >
              Light
            </span>
          </li>

        </div>


        <div class="col-md-6 dark_btn">

          <li class="parent-list">
            <img class="sidebar-icon mx-1" src="./assets/Menu sections/sun.png" width="12%" />

            <span class="sidebar-menu">
              Dark
            </span>
          </li>

        </div>
      </div>-->
    </div>
  </div>

</div>

import { Injectable, HostListener, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from '../../environment/environment'
import { Observable } from 'rxjs';
import { of } from "rxjs";
import * as moment from 'moment';



export enum Tab {
    Flights = 'Flights',
    Trains = 'Trains',
    Bus = 'Bus',
    CarRentals = 'Car Rentals',
    Hotels = 'Hotels',
    Parcel = 'Parcel',
    Active = 'true',
    InActive = 'false'
}

const baseUrl = environment.base_url
@Injectable({
    providedIn: 'root'
})


export class GlobalService {

    statusOptions = [
        { id: '', text: 'Select' },
        { id: '1', text: 'Active' },
        { id: '0', text: 'In Active' },
    ];



    alertBox: boolean = false;
    alertContent: any;
    alertType: any = "";
    defLimit: any = "";
    sortingIconClass: any = 'bi bi-arrow-down-up';
    authData: any = false;
    isauth: any;
    constructor(private router: Router) {
        this.calculateMinHeight();
    }


    ticketData: any;
    tab: any;
    toggleSidemenu: boolean = true;
    arrowIcon: any = 'bi bi-chevron-double-left';
    companyLogo: any = './assets/images/SanTMS.png';
    imageSize: any = '160px';


    togglesidebar() {
        this.toggleSidemenu = !this.toggleSidemenu;
        if (this.toggleSidemenu == false) {
            this.arrowIcon = 'bi bi-chevron-double-right';
            this.companyLogo = './assets/images/Sansoftwares_favicon.png';
            this.imageSize = '31px';
        } else {
            this.arrowIcon = 'bi bi-chevron-double-left';
            this.companyLogo = './assets/images/SanTMS.png';
            this.imageSize = '160px';
        }
    }

    toast($msg: any, $type = 'success') {
        if ($msg != '') {
            this.alertBox = true;
            this.alertContent = $msg;
            this.alertType = $type;
        } else {
            this.alertBox = false;
            this.alertContent = '';
            this.alertType = '';
        }
    }

    minHeight: any;

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.calculateMinHeight();
    }

    calculateMinHeight() {
        const screenHeight = window.innerHeight;
        this.minHeight = (screenHeight - 330) + 'px';
    }

    redirect(url: any = "", pageLoad = true) {

        if (url == 'booking') {
            this.getAuthData();
        }
        if (pageLoad) {
            window.location.replace(url);
        } else {
            this.router.navigate([url]);
        }
    }


    setAuthData(data: any) {
        this.setIntoLocalStorage('userdata', JSON.stringify(data));
    }

    setIntoLocalStorage(key: string, data: string) {
        localStorage.setItem(key, data);
    }


    getAuthData() {
        let data = this.getFromLocalStorage('userdata');

        if (data != '' && data != null && data != '0') {
            this.authData = JSON.parse(data);
        } else {
            console.log(this.authData, "this.authData")
        }
        return this.authData ? true : false;
    }

    getFromLocalStorage(key: string) {

        if (localStorage.getItem(key)) {
            let data = localStorage.getItem(key);
            return data;
        }
        return '';
    }
}


export const formatDateToYYYYMMDD = (date:Date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-indexed
  const dd = String(date.getDate()).padStart(2, '0');

  return `${yyyy}-${mm}-${dd}`;
}


export const formatDateToYYYYMM = (dateInput:any) => {
  return moment(dateInput).format('DD MMM, YYYY');
}
